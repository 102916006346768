import { attr, belongsTo, hasMany } from '@ember-data/model';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import { apiAction } from '@mainmatter/ember-api-actions';
import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

import BaseReceivableInvoiceModel from './receivable-invoice/base';

/**
 * @typedef FrequencyHash
 * @type {object}
 * @property {integer} value
 * @property { 'weekly' | 'monthly' | 'quarterly' | 'yearly'} recurrence
 */

/**
 * @typedef EmailTemplateHash
 * @type {object}
 * @property {string} emailTitle
 * @property {string} emailBody
 * @property {string} sendTo
 * @property {boolean} copyToSelf
 */

export default class InvoiceSubscriptionModel extends BaseReceivableInvoiceModel {
  /** @type {string} */
  @attr startDate;
  /** @type {string} */
  @attr endDate;
  /** @type {string} */
  @attr nextInvoiceDate;
  /** @type {string} */
  @attr prevInvoiceDate;
  /** @type {string} */
  @attr customerName;
  /** @type {FrequencyHash} */
  @attr('hash') frequency;
  /** @type {integer} */
  @attr paymentTermsDays;
  /** @type {EmailTemplateHash} */
  @attr('hash') emailTemplate;
  /** @type {boolean} */
  @attr('boolean', { defaultValue: false }) directDebitEnabled;
  /** @type {boolean} */
  @attr('boolean', { defaultValue: false }) isEinvoice;

  @tracked bic;
  @tracked iban;

  /** @type {Item} */
  @hasMany('receivable-invoice/item', { async: false, inverse: 'invoiceSubscription' }) items;

  /** @type {DirectDebitCollectionMandate} */
  @belongsTo('direct-debit-collection-mandate', { async: true, inverse: null })
  directDebitCollectionMandate;

  @waitFor
  async reactivate() {
    let response = await apiAction(this, { method: 'POST', path: 'reactivate' });
    this.store.pushPayload('invoice-subscription', response);
  }

  @waitFor
  async suspend() {
    let response = await apiAction(this, { method: 'POST', path: 'suspend' });
    this.store.pushPayload('invoice-subscription', response);
  }

  // This is used for compatibility with the PDF Preview component
  get issueDate() {
    return this.startDate;
  }

  // This is used for compatibility with the PDF Preview component
  get dueDate() {
    return dayjs(this.startDate)
      .add(this.paymentTermsDays, 'days')
      .format(DATE_PICKER_FIELD_FORMAT);
  }
}
