// @ts-nocheck
/* eslint-disable require-await */
import { ForbiddenError, NotFoundError } from '@ember-data/adapter/error';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { dependentKeyCompat } from '@ember/object/compat';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import { apiAction } from '@mainmatter/ember-api-actions';
import * as Sentry from '@sentry/ember';
import { task } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import macro, { bool, equal, gt, sort } from 'macro-decorators';

import {
  ACCOUNT_STATUS,
  ACCOUNT_TYPE,
  MAX_REMUNERATED_ACCOUNTS,
} from 'qonto/constants/bank-account';
import {
  BANK_CONNECTION_ACTIONABLE_STATUSES,
  BANK_CONNECTION_FAILURE_STATUSES,
  BANK_CONNECTIONS_PAGE_SIZE,
} from 'qonto/constants/connect';
import { DOCUMENT_TYPES } from 'qonto/constants/document';
import {
  ASSOCIATION_LEGAL_CODES,
  DE_COMPANY_CREATION_LEGAL_CODES,
  DE_FREELANCERS_LEGAL_CODES,
} from 'qonto/constants/legal-codes';
import {
  DEPOSIT_CAPITAL_FLOW_TYPE,
  DEPOSIT_CAPITAL_STATUS,
  FAST_TRACK_DEPOSIT_CAPITAL_STATUS,
  GBR_DE,
  GEO_EXPANSION_LEGAL_COUNTRIES,
} from 'qonto/constants/organization';
import { LEGAL_FORMS_SHORTCUTS } from 'qonto/constants/short-legal-forms';
import { ErrorInfo } from 'qonto/utils/error-info';
import transformKeys from 'qonto/utils/transform-keys';
import OrganizationValidations from 'qonto/validations/organization';

export default class Organization extends Model.extend(OrganizationValidations) {
  @attr('boolean') accessDisabled; // backoffice disabled organization
  @attr('number') accountantsCountingTowardsPlanLimitCount;
  @attr('string')
  declare slug: string;

  @attr('string') name;
  @attr checkSettings;
  @attr('string') contractStatus;
  @attr('string') depositCapitalFlowType;
  @attr('string') depositCapitalStatus;
  @attr('boolean') draft;
  @attr('string') legalName;
  @attr('string') legalForm;
  @attr legalCode;
  @attr('string') legalSector;
  @attr('string') legalNumber;
  @attr('number') legalShareCapital;
  @attr('string') legalRegistrationDate; // In the format "yyyy-mm-dd"
  @attr('string') contactEmail;
  @attr('string') kybStatus;
  @attr('string') status; // kyb_waiting, activated, deactivated, suspended
  @attr('string') fxStatus;
  @attr('string') phoneNumber;
  @attr('number') membershipsCount;
  @attr('number') membershipsCountingTowardsPlanLimitCount;
  @attr('number') activeMembershipsCount;
  @attr('date') createdAt;
  @attr('string') incomeRange;
  @attr('boolean') underRegistration;
  @attr('boolean') unreferenced;
  @attr('boolean') unlimited;
  @attr('string') documentId;
  @attr('string', { defaultValue: 'standard' }) originalPricePlanCode;
  @attr('number') requestsCount;
  @attr vatRatesCategories;
  @attr features;
  @attr('string') referralUrl;
  @attr('string') referralCode;
  @attr('string') coreBankingSystem;
  @attr('string') legalCountry;
  @attr legalTvaNumber;
  @attr('date') contractSignedAt;
  @attr('date') bylawUploadedAt;
  @attr('file') avatar;
  @attr('file') avatarThumb;
  @attr('file') defaultAvatar;
  @attr('file') defaultAvatarThumb;
  @attr providerSource;
  @attr providerSourceContactEmail;
  @attr taxResidenceCountry;
  @attr taxResidenceTaxIdentificationNumber;
  @attr estimatedYearlyIncome;
  @attr euVatNumber;
  @attr websiteUrl;
  @attr activityDescription;
  @attr tradeName;
  @attr('hash') referrer;
  @attr('string') onboardingPartner;
  @attr('string') onboardingPartnerType;
  @attr('boolean') companyCreationFromScratch;

  // Associations need to provide more documents,
  // which are gathered via a contextualised Typeform
  @attr associationTypeformFilledAt;
  @attr associationTypeformUrl;

  @belongsTo('address', { async: false, inverse: null }) address;
  @belongsTo('flex-kyb', { async: false, inverse: 'organization' }) flexKyb;
  @belongsTo('kyc-kyb-update-process', { async: true, inverse: 'organization' })
  ongoingKycKybUpdateProcess;
  @belongsTo('organization-kyb-detail', { async: false, inverse: 'organization' }) kybDetails;
  @hasMany('document', { async: false, inverse: 'organization' }) documents;
  @hasMany('label-list', { async: false, inverse: 'organization' }) labelLists;
  @hasMany('label', { async: false, inverse: 'organization' }) labels;
  @hasMany('membership', { async: false, inverse: 'organization' }) memberships;
  @hasMany('stakeholder', { async: false, inverse: 'organization' }) stakeholders;

  //Attr set for front purposes
  @attr('string') documentType;

  //Attrs set for invoices
  @attr('string') vatNumber;
  @attr('string') taxNumber;
  @attr('string') districtCourt;
  @attr('string') commercialRegisterNumber;
  @attr('string') companyLeadership;

  //For capital increase flow
  @attr('boolean') notaryMentionsIncludedInByLaws;

  @attr('number') referralRewardAmountCents;
  @attr('number') referralRewardEligibilityMinimumTransactionsAmountCents;
  //---------------------------

  @service abilities;
  @service sentry;

  task = null; // a task maybe attached to Organization to display upload progress

  // object containing { max_amount_without_attachment_cents: number }
  // used to define the transfer attachment requirement across apps
  @attr transferSettings;

  f24ActivationCompleted = null;
  f24ActivationRequested = null;
  f24ComputedFiscalCode = null;

  @tracked isDefaultAvatar = null;

  get shareholders() {
    return this.stakeholders.filter(s => s.shareholder);
  }

  @dependentKeyCompat
  get byLaws() {
    return this.documents.find(d => d.docType === DOCUMENT_TYPES.BY_LAW);
  }

  @dependentKeyCompat
  get proofOfAddress() {
    return this.documents.find(d => d.docType === DOCUMENT_TYPES.PROOF_OF_ADDRESS);
  }

  @dependentKeyCompat
  get kbis() {
    return this.documents.find(d => d.docType === DOCUMENT_TYPES.KBIS);
  }

  @equal('status', 'deactivated') isDeactivated;
  @equal('status', 'suspended') isSuspended;

  @equal('kybStatus', 'accepted') kybAccepted;
  @equal('kybStatus', 'pending') kybPending;
  @equal('kybStatus', 'refused') kybRefused;

  @equal('contractStatus', 'signed') contractSigned;
  @equal('contractStatus', 'signed') signed;
  @equal('contractStatus', 'signature_requested') signatureRequested;

  @equal('depositCapitalFlowType', DEPOSIT_CAPITAL_FLOW_TYPE.FAST_TRACK)
  isFastTrackDepositCapitalFlowType;

  @equal('depositCapitalStatus', DEPOSIT_CAPITAL_STATUS.PENDING_REVIEW) pendingReview;
  @equal('depositCapitalStatus', DEPOSIT_CAPITAL_STATUS.WAITING) waiting;
  @equal('depositCapitalStatus', DEPOSIT_CAPITAL_STATUS.DEPOSIT_REQUEST_SENT) depositRequestSent;
  @equal('depositCapitalStatus', DEPOSIT_CAPITAL_STATUS.DEPOSIT_REQUEST_SIGNED)
  depositRequestSigned;
  @equal('depositCapitalStatus', DEPOSIT_CAPITAL_STATUS.DEPOSIT_CERTIFICATE_SIGNED)
  depositCertificateSigned;
  @equal('depositCapitalStatus', DEPOSIT_CAPITAL_STATUS.KBIS_SUBMITTED) kbisSubmitted;
  @equal('depositCapitalStatus', DEPOSIT_CAPITAL_STATUS.DEPOSIT_RELEASE_SENT) depositReleaseSent;
  @equal('depositCapitalStatus', DEPOSIT_CAPITAL_STATUS.DEPOSIT_RELEASE_REQUESTED)
  depositReleaseRequested;

  @equal('fxStatus', 'fx_approved') isFxApproved;
  @equal('fxStatus', 'fx_kyc_waiting') isFxKycWaiting;
  @equal('fxStatus', 'fx_flex_kyb_waiting') isFxFlexKybWaiting;
  @equal('fxStatus', 'fx_error') isFxError;

  @hasFeature('account_closing_flow') hasAccountClosingFlow;
  @hasFeature('migration_completed') hasMigrationCompletedFeature;
  @hasFeature('bank_switch') hasBankSwitchFeature;
  @hasFeature('topups_enabled') hasTopUpFeature;
  @hasFeature('kyc_kyb_update') hasKycKybUpdateFeature;
  @hasFeature('pay_later') hasPayLaterFeature;
  @hasFeature('prevent_pay_later_transfer') hasPreventPayLaterToggle;
  @hasFeature('tiered_referral_referrer') hasTieredReferralReferrer;
  @hasFeature('international_transfers_v3') hasInternationalOutFeature;
  @hasFeature('product_discovery_system') hasProductDiscoverySystemFeature;
  @hasFeature('fourthline_workflow') hasFourthlineWorkflow;
  @hasFeature('modular_pricing_show_addons') hasModularPricing;
  @hasFeature('modular_pricing_give_ar_addon_for_free') hasArAddonForFree;

  get isGeoExpansionLegalCountry() {
    return GEO_EXPANSION_LEGAL_COUNTRIES.includes(this.legalCountry);
  }

  get isItalian() {
    return this.legalCountry === 'IT';
  }

  get isFrench() {
    return this.legalCountry === 'FR';
  }

  get isGerman() {
    return this.legalCountry === 'DE';
  }

  get isSpanish() {
    return this.legalCountry === 'ES';
  }

  get activeAccounts() {
    return this.bankAccounts.filter(ba => ba.isActive);
  }

  get internalAccounts() {
    return this.bankAccounts.filter(({ isExternalAccount }) => !isExternalAccount);
  }

  get activeSortedAccounts() {
    return this.sortedBankAccounts.filter(sba => sba.isActive);
  }

  get closedSortedAccounts() {
    return this.sortedBankAccounts.filter(sba => sba.isClosed);
  }

  get activeSortedQontoAccounts() {
    return this.activeSortedAccounts.filter(asa => !asa.isExternalAccount);
  }

  get activeSortedCurrentAccounts() {
    return this.activeSortedAccounts.filter(sba => !sba.isRemunerated);
  }

  get closedSortedCurrentAccounts() {
    return this.closedSortedAccounts.filter(sba => !sba.isRemunerated);
  }

  get pendingAccounts() {
    return this.bankAccounts.filter(ba => ba.isPending);
  }

  get organizationId(): string {
    return this.id;
  }

  get activeOrPendingBankAccounts() {
    return this.bankAccounts?.filter(
      ({ isExternalAccount, isRemunerated, status }) =>
        !isExternalAccount &&
        !isRemunerated &&
        [ACCOUNT_STATUS.PENDING, ACCOUNT_STATUS.ACTIVE].includes(status)
    );
  }

  get bankAccountsBalance() {
    let bankAccounts = this.bankAccounts;
    if (!bankAccounts) return;

    let sum = 0;
    for (let account of bankAccounts) {
      let balance = account.authorizedBalance;
      if (balance === undefined) return;

      sum += balance;
    }
    return sum;
  }

  get remuneratedAccounts() {
    return this.bankAccounts.filter(({ isRemunerated }) => isRemunerated);
  }

  get nonClosedRemuneratedAccounts() {
    return this.remuneratedAccounts.filter(({ status }) =>
      [ACCOUNT_STATUS.ACTIVE, ACCOUNT_STATUS.WAITING].includes(status)
    );
  }

  get isMaxRemuneratedAccountNumberReached() {
    return this.nonClosedRemuneratedAccounts.length === MAX_REMUNERATED_ACCOUNTS;
  }

  get activeRemuneratedAccounts() {
    return this.remuneratedAccounts.filter(({ status }) =>
      [ACCOUNT_STATUS.ACTIVE].includes(status)
    );
  }

  get activeSortedRemuneratedAndCurrentAccounts() {
    let currentAccounts = [];
    let remuneratedAccounts = [];
    let activeSortedAccounts = this.activeSortedAccounts;

    for (let account of activeSortedAccounts) {
      if (!account.isRemunerated) {
        currentAccounts.push(account);
      } else {
        remuneratedAccounts.push(account);
      }
    }
    return currentAccounts.concat(remuneratedAccounts);
  }

  get internalBankAccounts() {
    return this.bankAccounts.filter(({ isExternalAccount }) => !isExternalAccount);
  }

  get sortedAlphabeticallyInternalAccounts() {
    return this.internalAccounts.sort((a, b) => a.name.localeCompare(b.name));
  }

  @gt('bankAccounts.length', 1) hasMultipleBankAccounts;

  get hasMultipleActiveAccounts() {
    return this.activeSortedCurrentAccounts.length > 1;
  }

  get hasMultipleActiveCurrentRemuneratedAccounts() {
    return this.activeAccounts.length > 1;
  }

  @bool('avatar.fileUrl') hasAvatar;

  get picture() {
    let hasNoAvatar = !this.hasAvatar;
    let hasDefaultAvatar = this.get('defaultAvatar.fileUrl');
    return hasNoAvatar && hasDefaultAvatar ? this.defaultAvatar : this.avatar;
  }

  get pictureThumb() {
    let hasNoAvatar = !this.hasAvatar;
    let hasDefaultAvatarThumb = this.get('defaultAvatarThumb.fileUrl');

    return hasNoAvatar && hasDefaultAvatarThumb ? this.defaultAvatarThumb : this.avatarThumb;
  }

  /**
   * @description IMPORTANT: certain users might not have access to the main bank account of the organization
   * resulting in the following function returning undefined
   *
   * @returns {BankAccount | undefined} The main bank account or first active account if the main one is not accessible.
   */
  get mainAccount() {
    return this.bankAccounts.find(ba => ba.main) ?? this.firstActiveAccount;
  }

  get firstActiveAccount() {
    return this.activeSortedCurrentAccounts[0];
  }

  get defaultAccount() {
    return this.mainAccount ?? this.firstActiveAccount;
  }

  get primaryAccount() {
    return this.sortedBankAccounts[0];
  }

  get referralRewardAmount() {
    if (!this.referralRewardAmountCents || this.referralRewardAmountCents < 0) {
      return 0;
    }
    return this.referralRewardAmountCents / 100;
  }

  get referralRewardEligibilityMinimumTransactionsAmount() {
    if (
      !this.referralRewardEligibilityMinimumTransactionsAmountCents ||
      this.referralRewardEligibilityMinimumTransactionsAmountCents < 0
    ) {
      return 0;
    }
    return this.referralRewardEligibilityMinimumTransactionsAmountCents / 100;
  }

  get isAssociationKybPending() {
    let {
      legalCode,
      associationTypeformFilledAt,
      associationTypeformUrl,
      kybPending: isKybPending,
    } = this;

    return (
      isKybPending &&
      associationTypeformUrl &&
      !associationTypeformFilledAt &&
      ASSOCIATION_LEGAL_CODES.includes(legalCode)
    );
  }

  /**
   * isDeFreelancer = isEinzelunternehmen
   *
   * which corresponds to legal forms  Freiberufler and Einzelunternehmer.
   */
  get isDeFreelancer() {
    return DE_FREELANCERS_LEGAL_CODES.includes(this.legalCode);
  }

  /**
   * isDeCompanyCreation = in Gründung (i.G.)
   *
   * which corresponds to legal forms  GmbH in Gründung (i.G.)
   * and UG in Gründung (i.G.)
   */
  get isDeCompanyCreation() {
    return DE_COMPANY_CREATION_LEGAL_CODES.includes(this.legalCode);
  }

  get isGbR() {
    return this.legalForm === GBR_DE;
  }

  get isFastTrackDepositCapitalStatus() {
    return Object.values(FAST_TRACK_DEPOSIT_CAPITAL_STATUS).includes(this.depositCapitalStatus);
  }

  get shortLegalForm() {
    let shortLegalForm = LEGAL_FORMS_SHORTCUTS[this.legalForm];

    if (this.legalForm && !shortLegalForm) {
      Sentry.captureMessage(
        `LEGAL_FORMS_SHORTCUTS need to be updated, the LegalForm : ${this.legalForm} was not found`
      );
    }

    return shortLegalForm;
  }

  get hasOngoingKycKybUpdateProcess() {
    return (
      this.hasKycKybUpdateRequest &&
      this.abilities.can('read kyc kyb update process organization') &&
      !(
        Boolean(this.get('ongoingKycKybUpdateProcess.inReview')) ||
        Boolean(this.get('ongoingKycKybUpdateProcess.isAccepted'))
      )
    );
  }

  get hasKycKybUpdateRequest() {
    return Boolean(this.belongsTo('ongoingKycKybUpdateProcess').id());
  }

  get bankAccounts() {
    return this.loadBankAccountsTask.lastSuccessful?.value;
  }

  get savingsAccounts() {
    return this.fetchSavingsAccountsTask.lastSuccessful?.value;
  }

  get hasExternalAccounts() {
    if (!this.bankAccounts) return false;

    return this.bankAccounts.some(account => account.isExternalAccount);
  }

  get bankConnections() {
    return this.loadBankConnectionsTask.lastSuccessful?.value;
  }

  get bankConnectionsStatuses() {
    if (!this.bankConnections) return;

    let failedConnections = this.bankConnections.filter(({ status }) =>
      BANK_CONNECTION_FAILURE_STATUSES.includes(status)
    );
    let actionableFailures = failedConnections.filter(({ status }) =>
      BANK_CONNECTION_ACTIONABLE_STATUSES.includes(status)
    );

    return {
      failedConnections,
      actionableFailures,
    };
  }

  @waitFor
  async loadBankAccounts() {
    return this.loadBankAccountsTask.perform();
  }

  @waitFor
  async loadBankConnections() {
    return this.loadBankConnectionsTask.perform().catch(error => {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    });
  }

  async loadSavings() {
    return this.fetchSavingsAccountsTask.perform().catch(() => {});
  }

  loadTeams() {
    return this.loadTeamsTask.perform();
  }

  fetchSavingsAccountsTask = task(async () => {
    return variation('feature--boolean-term-deposit-removal')
      ? []
      : await this.store.query('savings-account', {
          organization_id: this.id,
        });
  });

  loadBankAccountsTask = task(async () => {
    if (this.underRegistration) return [];

    let queryOptions = {
      organization_id: this.id,
      per_page: 500,
      account_types: [ACCOUNT_TYPE.CURRENT, ACCOUNT_TYPE.REMUNERATED, ACCOUNT_TYPE.OTHER],
    };
    let result = await this.store.query('bank-account', queryOptions);
    return [...result];
  });

  loadBankConnectionsTask = task(async () => {
    let result = await this.store.query('bank-connection', {
      per_page: BANK_CONNECTIONS_PAGE_SIZE,
    });

    return [...result];
  });

  loadTeamsTask = task(async () => {
    let result = await this.store.query('team', { organization_id: this.id, per_page: 100 });
    this.set('teams', [...result]);
  });

  getOldInvoicesTask = task(async () => {
    return await this.store.query('invoice', {
      organization_id: this.id,
      postMigration: true,
    });
  });

  @sort('bankAccounts', (a, b) => a.createdAt - b.createdAt) sortedBankAccounts;

  @accountBalanceSum('authorizedBalance') authorizedBalance;
  @accountBalanceSum('balance') balance;
  @accountBalanceSum('processingBalance') processingBalance;
  @remuneratedAccountBalanceSum('authorizedBalance') remuneratedAuthorizedBalance;

  deactivationData = null;

  @waitFor
  async getDeactivation(force = true) {
    if (this.hasAccountClosingFlow && (force || !this.deactivationData)) {
      this.deactivationData = await apiAction(this, { method: 'GET', path: 'deactivation' }).then(
        transformKeys
      );
    }

    return this.deactivationData;
  }

  @waitFor
  async createDeactivation(data) {
    let res = await apiAction(this, { method: 'POST', path: 'deactivation', data });
    this.deactivationData = null;
    return res;
  }

  @waitFor
  async cancelDeactivation() {
    let res = await apiAction(this, { method: 'DELETE', path: 'deactivation' });
    this.deactivationData = null;
    return res;
  }

  @waitFor
  async checkDeactivationRequirements() {
    return await apiAction(this, { method: 'GET', path: 'deactivation_requirements' }).then(
      transformKeys
    );
  }

  @waitFor
  async handleContractSigned(documentId) {
    this.set('documentId', documentId);

    let data = {
      organization: { document_id: this.documentId, document_type: this.documentType },
    };

    let response = await apiAction(this, { method: 'PUT', path: 'sign', data });

    let serializer = this.store.serializerFor('organization');
    let normalizedRecord = serializer.normalizeSingleResponse(
      this.store,
      this.constructor,
      response,
      `${response?.organization?.id || '(unknown)'}`,
      'findRecord'
    );

    return this.store.push(normalizedRecord);
  }

  @waitFor
  async getNotary() {
    return await apiAction(this, { method: 'GET', path: 'notary' });
  }

  @waitFor
  async requestDepositCertificate() {
    return await apiAction(this, { method: 'GET', path: 'deposit_certificate' });
  }

  @waitFor
  async requestUpdatedBylaws() {
    return await apiAction(this, { method: 'GET', path: 'updated_bylaws' });
  }

  @waitFor
  async requestSignature() {
    let data = { organization: { document_type: this.documentType } };
    let response = await apiAction(this, { method: 'POST', path: 'sign', data });
    return response.signature;
  }

  @waitFor
  async getOldStatements(data) {
    let response = await apiAction(this, { method: 'GET', path: 'migration/old_statements', data });
    this.store.pushPayload('statement', response);

    return response.statements.map(statement => this.store.peekRecord('statement', statement.id));
  }

  @waitFor
  async exportOldTransactions({ withAttachments, fileType, separator }) {
    let data = {
      separator,
      with_attachments: withAttachments,
      file_type: fileType,
    };
    return await apiAction(this, { method: 'POST', path: 'migration/old_transactions', data });
  }

  @waitFor
  async saveDocuments(docType) {
    let documents = docType ? this.documents.filter(d => d.docType === docType) : this.documents;

    let data = {
      organization: {
        documents: [...documents].map(doc => doc.serialize()),
      },
    };

    let response = await apiAction(this, { method: 'POST', path: 'documents', data });

    let serializer = this.store.serializerFor('organization');
    let normalizedRecord = serializer.normalizeSingleResponse(
      this.store,
      this.constructor,
      response,
      `${response.organization.id || '(unknown)'}`,
      'findRecord'
    );

    return this.store.push(normalizedRecord);
  }

  @waitFor
  async updateDepositCapitalStatus() {
    let response = await apiAction(this, { method: 'PUT', path: 'complete_shareholders' });
    this.store.pushPayload(response);
  }

  @waitFor
  async getF24Activation() {
    try {
      let response = await apiAction(this, { method: 'GET', path: 'f24_activation' });
      let { completed, requested } = response.f24_activation;

      this.setProperties({
        f24ActivationCompleted: completed,
        f24ActivationRequested: requested,
      });
    } catch (error) {
      if (error instanceof NotFoundError) {
        this.setProperties({
          f24ActivationCompleted: false,
          f24ActivationRequested: false,
        });
      } else {
        throw error;
      }
    }
  }

  @waitFor
  async f24ComputeFiscalCode() {
    try {
      let { computed_fiscal_code } = await apiAction(this, {
        method: 'GET',
        path: 'f24_compute_fiscal_code',
      });
      this.set('f24ComputedFiscalCode', computed_fiscal_code);
    } catch (error) {
      if (error instanceof NotFoundError) {
        this.set('f24ComputedFiscalCode', null);
      } else {
        throw error;
      }
    }
  }

  @waitFor
  async completeF24Activation({ fiscalCode, contractFilename }) {
    let data = {
      f24_activation: {
        fiscal_code: fiscalCode,
        contract_filename: contractFilename,
      },
    };

    let response = await apiAction(this, {
      method: 'POST',
      path: 'f24_activation/complete',
      data,
    });

    let { completed, requested } = response.f24_activation;
    this.setProperties({
      f24ActivationCompleted: completed,
      f24ActivationRequested: requested,
    });
  }

  @waitFor
  async requestF24Activation() {
    let response = await apiAction(this, { method: 'POST', path: 'f24_activation/request' });

    let { completed, requested } = response.f24_activation;
    this.setProperties({
      f24ActivationCompleted: completed,
      f24ActivationRequested: requested,
    });
  }

  @waitFor
  async getAvatar() {
    try {
      let response = await apiAction(this, { method: 'GET', path: 'avatars' });
      let {
        is_default,
        avatar_url,
        avatar_thumb_url,
        default_avatar_url,
        default_avatar_thumb_url,
      } = response.data.attributes;

      // We set the properties to rerender elements using the URLs
      this.setProperties({
        isDefaultAvatar: is_default,
        'avatar.fileUrl': avatar_url,
        'avatarThumb.fileUrl': avatar_thumb_url,
        'defaultAvatar.fileUrl': default_avatar_url,
        'defaultAvatarThumb.fileUrl': default_avatar_thumb_url,
      });
    } catch (error) {
      if (error instanceof NotFoundError) {
        this.setProperties({ isDefaultAvatar: null });
      } else {
        throw error;
      }
    }
  }

  @waitFor
  async createFlexKybSubmission({ legalNumber, kbisFileId }) {
    return await apiAction(this, {
      method: 'POST',
      path: 'flex_kyb_submission',
      data: {
        legal_number: legalNumber,
        kbis_file_id: kbisFileId,
      },
    });
  }

  @waitFor
  async loadFlexKyb() {
    try {
      let response = await apiAction(this, {
        method: 'GET',
        path: 'flex_kyb',
      });
      this.store.pushPayload('flex_kyb', response);
    } catch (error) {
      this.set('flexKyb', null);

      if (error instanceof ForbiddenError || error instanceof NotFoundError) {
        // 403 is expected to happen often as the membership could not have the organizations.update permission
        // 404 is expected to happen often as only DE Freelancers organizations have a flex_kyb
      } else {
        throw error;
      }
    }
  }
}

function accountBalanceSum(property) {
  return macro(function () {
    if (!this.bankAccounts) return;

    let bankAccounts = this.bankAccounts.filter(
      ({ accountType }) => accountType !== ACCOUNT_TYPE.REMUNERATED
    );

    let sum = 0;
    for (let account of bankAccounts) {
      let balance = account[property];
      if (balance === undefined) return;

      sum += balance;
    }
    return sum;
  });
}

function remuneratedAccountBalanceSum(property) {
  return macro(function () {
    if (!this.activeRemuneratedAccounts) return;

    let sum = 0;
    for (let account of this.activeRemuneratedAccounts) {
      let balance = account[property];
      if (balance === undefined) return;

      sum += balance;
    }
    return sum;
  });
}

function hasFeature(name) {
  return macro(function () {
    return this.features?.includes(name);
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    organization: Organization;
  }
}
