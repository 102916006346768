import { InvalidError } from '@ember-data/adapter/error';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { isNone, isPresent } from '@ember/utils';

import { apiAction } from '@mainmatter/ember-api-actions';
import { dropTask, task } from 'ember-concurrency';
import { and, bool, equal, or } from 'macro-decorators';

import { CHECK_COMPLETED_STATUSES } from 'qonto/constants/checks';
import { R_TRANSACTION_TYPES } from 'qonto/constants/direct-debit-collections';
import { CLAIM_STATUSES, STATUS, SUBJECT_TYPES } from 'qonto/constants/transactions';
import { OPERATION_TYPES } from 'qonto/constants/transfers';
import { errorsArrayToHash } from 'qonto/utils/errors-array-to-hash';

const oneSideOperationMethods = ['card', 'cheque'];

export default class TransactionModel extends Model {
  @service intl;
  @service networkManager;
  @service abilities;

  @attr('string') slug;
  @attr('string') sequentialId;
  @attr('date') emittedAt;
  @attr('date') displayAt;
  @attr('string') subjectId;
  @attr('string') subjectType;
  @attr('date') settledAt;
  @attr('number') amount;
  @attr('number') localAmount;
  @attr('string') amountCurrency;
  @attr('string') localAmountCurrency;
  @attr('hash', {
    defaultValue: () => {
      return {};
    },
  })
  enrichmentData;

  /**
   * Single VAT management (v5)
   */
  @attr('number') vatAmount;
  @attr('string') vatCountry;
  @attr('number') vatRate;
  @attr('string') vatStatus;

  /**
   * Multi-VAT (v6)
   */
  @attr() vat;

  /**
   * External transactions (v7)
   */
  @attr() counterparty;

  @attr('string') side;
  @attr('string') counterpartyName;
  @attr('string') rawCounterpartyName;
  @attr('string') note;
  @attr('string') description;
  @attr('boolean') attachmentRequested;
  @attr('string') status;
  @attr() declinedReason;
  @attr('string') operationMethod;
  @attr('string') operationType;
  @attr('boolean') reported;
  @attr('boolean', { defaultValue: false }) blockCard;
  @attr('string') explanation;
  @attr('boolean') fx;
  @attr('string') initiatorId;
  @attr('boolean') attachmentLost;
  @attr('boolean') attachmentRequired;
  @attr('string') activityTag;
  @attr('string') cardTransactionStatus;
  @attr('boolean', { defaultValue: false }) qualifiedForAccounting;

  @belongsTo('bank-account', { async: false, inverse: null }) bankAccount;
  @hasMany('label', { async: false, inverse: 'transaction' }) labels;

  @hasMany('attachment', { async: false, inverse: null }) attachments;
  @attr() attachmentIds;
  @attr() attachmentSuggestionIds;
  @attr() automaticallyAddedAttachmentIds;

  @belongsTo('membership', { async: false, inverse: null }) initiator;
  @belongsTo('subject', { async: true, inverse: null, polymorphic: true }) subject;

  @equal('side', 'debit') debit;
  @equal('side', 'credit') credit;
  @equal('operationMethod', 'pay_later') isPayLater;
  @equal('operationMethod', 'biller') isBiller;
  @equal('subjectType', SUBJECT_TYPES.FINANCING_INSTALLMENT) isFinancingInstallment;
  @equal('subjectType', SUBJECT_TYPES.FINANCING_INCOME) isFinancingIncome;

  get attachmentCount() {
    return this.attachmentIds?.length ?? 0;
  }

  get isPagoPaPayment() {
    return this.operationMethod === 'pagopa_payment';
  }

  get isNrcPayment() {
    return this.subjectType === SUBJECT_TYPES.NRC_PAYMENT;
  }

  get isUnknown() {
    return this.operationMethod === 'other';
  }

  get signedAmount() {
    if (this.amount >= 0) {
      return this.debit ? -this.amount : this.amount;
    }
    return this.amount;
  }

  get signedLocalAmount() {
    return this.debit ? -this.localAmount : this.localAmount;
  }

  get completed() {
    if (this.isCheckTransaction) {
      return CHECK_COMPLETED_STATUSES.includes(this.subject.get('status'));
    }

    return this.status === STATUS.COMPLETED;
  }

  @equal('status', 'reversed') reversed;

  get avatarInfo() {
    let avatarStatusIconSrc = null;
    let activityTagSVG = `/icon/category/${this.activityTag}-m.svg`;

    if (this.declined || this.reversed) {
      avatarStatusIconSrc = 'status_stop-xs';
    } else if (this.pending) {
      avatarStatusIconSrc = 'status_processing-s';
    }

    return {
      mediumLogo: this.enrichmentData.logo?.medium ?? activityTagSVG,
      smallLogo: this.enrichmentData.logo?.small ?? activityTagSVG,
      icon: avatarStatusIconSrc,
    };
  }

  get refunded() {
    if (this.isCheckTransaction) {
      return this.subject.get('status') === 'refunded';
    }
  }

  get pending() {
    if (this.isCheckTransaction) {
      return this.subject.get('status') === 'pending';
    }

    return this.status === 'pending';
  }

  get declined() {
    if (this.isCheckTransaction) {
      return this.subject.get('status') === 'declined';
    }

    return this.status === 'declined';
  }

  get canceled() {
    if (this.isCheckTransaction) {
      return this.subject.get('status') === 'canceled';
    }

    return (
      this.status === 'declined' &&
      (this.declinedReason === 'user_canceled' || this.declinedReason === 'ops_canceled')
    );
  }

  get isFx() {
    let amountCurrency = this.amountCurrency;
    let localAmountCurrency = this.localAmountCurrency;
    if (amountCurrency && localAmountCurrency) {
      return amountCurrency.toLowerCase() !== localAmountCurrency.toLowerCase();
    }

    return false;
  }

  get isExternalTransaction() {
    return isNone(this.subjectId) && isNone(this.subjectType);
  }

  get isCardTransaction() {
    if (this.isExternalTransaction) return this.operationMethod === 'card';

    return this.subjectType?.toLowerCase() === 'card';
  }

  get isCheckTransaction() {
    if (this.isExternalTransaction) return this.operationMethod === 'cheque';

    return this.subjectType?.toLowerCase() === 'check';
  }

  get isTransfer() {
    if (this.isExternalTransaction) return this.operationMethod === 'transfer';

    return this.subjectType?.toLowerCase() === 'transfer';
  }

  get isDirectDebitCollection() {
    if (this.isExternalTransaction) return this.operationMethod === 'direct_debit_collection';

    return this.subjectType === SUBJECT_TYPES.DIRECT_DEBIT_COLLECTION;
  }

  get isDirectDebitHold() {
    if (this.isExternalTransaction) return this.operationMethod === 'direct_debit_hold';

    return this.subjectType === SUBJECT_TYPES.DIRECT_DEBIT_HOLD;
  }

  get isSDDReturnedAfterSettlementDate() {
    return (
      this.isDirectDebitCollection &&
      this.subject.get('rTransactionType') &&
      this.subject.get('rTransactionType') === R_TRANSACTION_TYPES.RETURN
    );
  }

  get isSDDRefunded() {
    return (
      this.isDirectDebitCollection &&
      this.subject.get('rTransactionType') &&
      this.subject.get('rTransactionType') === R_TRANSACTION_TYPES.REFUND
    );
  }

  @equal('operationMethod', 'tax') isTax;

  @and('isTransfer', 'subject.isDeclined') isDeclinedTransfer;

  @and('isTransfer', 'subject.instant') isInstantTransfer;

  @and('isTransfer', 'subject.isRepeatable') isRepeatableTransfer;

  @and('isTransfer', 'subject.isInternationalOut') isInternationalOutTransfer;

  get isIncome() {
    return this.subjectType?.toLowerCase() === 'income';
  }

  get isDirectDebit() {
    if (this.isExternalTransaction) return this.operationMethod === 'direct_debit';

    return this.subjectType?.toLowerCase() === 'directdebit';
  }

  @bool('subject.isFee') isFee;
  @bool('subject.isSeizure') isSeizure;
  @bool('subject.isCreditNote') isCreditNote;
  @bool('subject.isSubscription') isSubscription;
  @or('isFee', 'isCreditNote', 'isSubscription') isBilling;

  @equal('subjectType', 'SwiftIncome') isSwiftIncome;

  get operationName() {
    let operationType = this.get('operationType');

    if (oneSideOperationMethods.includes(operationType)) {
      return operationType;
    }

    if (operationType === 'transfer' && this.get('isSeizure')) {
      return OPERATION_TYPES.SEIZURE;
    }

    if (operationType === 'f24' && this.isProxiedF24) {
      return 'f24_payment_proxied';
    }

    let desc = this.get('debit') ? 'out' : 'in';
    return `${operationType}_${desc}`;
  }

  get isNewF24() {
    let subjectIdExists = isPresent(this.subjectId);
    return this.subjectType?.toLowerCase() === 'f24payment' && subjectIdExists;
  }

  get isProxiedF24() {
    let subjectIdExists = !isNone(this.subjectId);

    return (
      this.subjectType?.toLowerCase() === 'f24payment' &&
      Boolean(this.subject.get('isProxied')) &&
      subjectIdExists
    );
  }

  get operationTypeTranslations() {
    return {
      biller: this.intl.t('transactions.operation-types.biller'),
      card: this.intl.t('transactions.operation-types.card'),
      cheque: this.intl.t('transactions.operation-types.cheque'),
      direct_debit: this.intl.t('transactions.operation-types.direct-debit'),
      direct_debit_hold: this.intl.t('transactions.operation-types.direct-debit-hold'),
      pagopa_payment: this.intl.t('transactions.operation-types.pagopa-payment'),
      tax: this.intl.t('transactions.operation-types.tax'),
      transfer: this.intl.t('transactions.operation-types.transfer'),
      pay_later: this.intl.t('transactions.operation-types.pay-later'),
      other: this.intl.t('transactions.operation-types.unknown'),
    };
  }

  get methodLabel() {
    let operationMethod = this.operationMethod;
    if (operationMethod) {
      let method = this.operationTypeTranslations[operationMethod];

      if (operationMethod === 'card') {
        let memberName = this.get('initiator.memberName');
        if (memberName) {
          return { method, name: memberName };
        }
      }
      return { method };
    }
  }

  get attachmentsFiles() {
    return this.attachments
      .filter(f => Boolean(f.file))
      .map(attachment => {
        let { file, probativeAttachment, vatStatus, thumbnail } = attachment;
        let thumbnailUrl = thumbnail?.fileUrl;
        let probativeStatus = probativeAttachment?.status;
        let isProcessing = this.abilities.can('probate attachment')
          ? vatStatus === 'vat_detecting' || probativeStatus === 'pending'
          : false;
        let attachmentFile = probativeStatus === 'available' ? probativeAttachment : file;

        if (probativeStatus === 'corrupted') {
          // we copy all file infos in the probative attachment object
          // as corrupted probativeAttachment object only contains a status
          return { ...file, status: probativeStatus };
        }

        // a file could be attach to a transaction without having been uploaded with web app,
        // as suggested attachment from invoice collector for example,
        // `file.state` could in that case never be defined.
        if ('state' in file && file.state !== 'uploaded') {
          // we use Object.assign since it preserves the local file
          // uploading properties like file.progress and file.state
          return Object.assign(attachmentFile, { isProcessing, thumbnailUrl });
        }

        // we use destructing instead of Object.assign since the
        // latter does not update when the isProcessing property changes

        return { ...attachmentFile, isProcessing, thumbnailUrl };
      });
  }

  get vatItems() {
    return this.vat.items;
  }

  get hasUndeletableAttachment() {
    let { isPayLater, debit, isTransfer } = this;
    return isPayLater && debit && isTransfer;
  }

  _getClaimTypesForStatuses(filterStatuses) {
    let claims = this.store.peekAll('claim').filter(claim => {
      let includesCurrentTransaction = claim.transactionIds.includes(this.id);
      let isRelevantStatus = filterStatuses.includes(claim.status);

      return includesCurrentTransaction && isRelevantStatus;
    });

    let claimTypeValues = claims.map(claim => {
      return claim.type;
    });

    let uniqueClaimTypeValues = [...new Set(claimTypeValues)];
    return uniqueClaimTypeValues;
  }

  get chargebackDisputingTypes() {
    return this._getClaimTypesForStatuses([CLAIM_STATUSES.review]);
  }

  get chargebackDisputedTypes() {
    let disputedStatuses = [CLAIM_STATUSES.rejected, CLAIM_STATUSES.approved];

    return this._getClaimTypesForStatuses(disputedStatuses);
  }

  getVatItem(index) {
    return this.vatItems[index];
  }

  _handleError(error) {
    if (error instanceof InvalidError && error.errors) {
      let errors = errorsArrayToHash(error.errors);
      this.networkManager.errorModelInjector(this, errors);
    }

    throw error;
  }

  @waitFor
  async requestAttachment() {
    let response = await apiAction(this, { method: 'PUT', path: 'request_attachment' });
    this.store.pushPayload(response);
  }

  linkAttachment(attachments) {
    return this.linkAttachmentTask.perform(attachments);
  }

  linkAttachmentTask = task({ maxConcurrency: 5, enqueue: true }, async attachments => {
    await this._linkAttachment(attachments.map(it => it.id));
  });

  @waitFor
  async _linkAttachment(attachmentIds) {
    let data = { transaction: { attachment_ids: attachmentIds } };
    try {
      let response = await apiAction(this, { method: 'PATCH', path: 'link_attachments', data });
      this.store.pushPayload(response);
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  async updateActivityTag(activityTag) {
    let data = { transaction: { activity_tag: activityTag } };
    let response = await apiAction(this, { method: 'PUT', path: 'activity', data });
    this.store.pushPayload(response);
  }

  @waitFor
  async updateAttachmentStatuses({ required, lost, organizationId, transactionIds }) {
    let data = {
      lost,
      organization_id: organizationId,
      transaction_ids: transactionIds,
    };

    if (this.abilities.can('update attachment required status transaction')) {
      data.required = required;
    }

    try {
      let response = await apiAction(this, {
        requestType: 'createRecord',
        method: 'PATCH',
        path: 'attachment_statuses',
        data,
      });
      this.store.pushPayload(response);
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  async markAsQualify({ organizationId, transactionIds }) {
    let data = { organization_id: organizationId, transaction_ids: transactionIds };
    try {
      let response = await apiAction(this, {
        requestType: 'createRecord',
        method: 'POST',
        path: 'qualify_for_accounting',
        data,
      });
      this.store.pushPayload(response);
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  async markAsDisqualify({ organizationId, transactionIds }) {
    let data = { organization_id: organizationId, transaction_ids: transactionIds };
    try {
      let response = await apiAction(this, {
        requestType: 'createRecord',
        method: 'POST',
        path: 'disqualify_for_accounting',
        data,
      });
      this.store.pushPayload(response);
    } catch (error) {
      this._handleError(error);
    }
  }

  unlinkAttachment(attachments) {
    return this.unlinkAttachmentTask.perform(attachments);
  }

  unlinkAttachmentTask = dropTask(async attachments => {
    await this._unlinkAttachment(attachments.map(it => it.id));
  });

  @waitFor
  async _unlinkAttachment(attachmentIds) {
    let data = { transaction: { attachment_ids: attachmentIds } };
    try {
      let response = await apiAction(this, { method: 'PATCH', path: 'unlink_attachments', data });
      this.store.pushPayload(response);
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  async markAttachmentAsLost() {
    let response = await apiAction(this, { method: 'PUT', path: 'mark_attachment_as_lost' });
    this.store.pushPayload(response);
  }

  @waitFor
  async unmarkAttachmentAsLost() {
    let response = await apiAction(this, { method: 'PUT', path: 'unmark_attachment_as_lost' });
    this.store.pushPayload(response);
  }

  @waitFor
  async markAttachmentAsRequired() {
    let response = await apiAction(this, { method: 'PUT', path: 'mark_attachment_as_required' });
    this.store.pushPayload(response);
  }

  @waitFor
  async unmarkAttachmentAsRequired() {
    let response = await apiAction(this, { method: 'PUT', path: 'unmark_attachment_as_required' });
    this.store.pushPayload(response);
  }
}
