import Model, { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';

import CURRENCIES from 'qonto/constants/currencies';
import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';
import { NetworkManagerError } from 'qonto/services/network-manager';

export default class SupplierModel extends Model {
  @service networkManager;

  @attr('string') name;
  @attr('string') email;
  @attr('string') phoneNumber;
  @attr('number') vatNumber;
  @attr('number') defaultVatRate;
  @attr('string') tinNumber;
  @attr('string') recipientCode;
  @attr('string') streetAddress;
  @attr('string') zipCode;
  @attr('string') provinceCode;
  @attr('string') city;
  @attr('string') countryCode;
  @attr('hash') outstandingBalance;
  @attr('string', { defaultValue: CURRENCIES.default }) currency;
  @attr('string') iban;
  @attr('string', { defaultValue: SUPPLIER_STATUSES.UNARCHIVED }) status;

  @belongsTo('organization', { async: false, inverse: null }) organization;

  @waitFor
  async save() {
    try {
      return await super.save(...arguments);
    } catch (error) {
      if ((error.isAdapterError || error instanceof NetworkManagerError) && error.errors) {
        let errors = this.#errorsArrayToHash(error.errors);
        this.networkManager.errorModelInjector(this, errors);
      }
      throw error;
    }
  }

  // this method comes from
  // https://github.com/emberjs/data/blob/f4bfbaa148b67104d0022fd176faa0f4a612289a/packages/store/addon/-private/system/errors-utils.js#L122
  // override to support nested errors
  #errorsArrayToHash(errors) {
    let out = {};
    if (errors) {
      errors.forEach(error => {
        if (error.source && error.source.pointer) {
          let key = error.source.pointer;

          if (key) {
            out[key] = out[key] || [];
            // only update: use the code as the message if it exists
            out[key].push(error.code || error.details || error.title);
          }
        }
      });
    }
    return out;
  }

  @waitFor
  async archive() {
    await apiAction(this, { method: 'POST', path: 'archive' });
    this.store.pushPayload('supplier', { ...this, status: SUPPLIER_STATUSES.ARCHIVED });
  }

  @waitFor
  async unarchive() {
    await apiAction(this, { method: 'POST', path: 'unarchive' });
    this.store.pushPayload('supplier', { ...this, status: SUPPLIER_STATUSES.UNARCHIVED });
  }
}
