import Model, { attr, belongsTo } from '@ember-data/model';
import { underscore } from '@ember/string';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';
import dayjs from 'dayjs';

import { Addons } from 'qonto/constants/addons';

const INITIAL_TRIAL_UPSELL_CUTOFF_DAYS = 1;
export default class OrganizationSubscriptionNewModel extends Model {
  @attr('string') status;
  @attr('string') nextRecurrenceDate;
  @attr('string') nextSubscriptionBillingDate;
  @attr('string') nextInvoicingDate;
  @attr('string') recurrence;
  @attr nextBillingDate;
  @attr activeTrial;
  @attr trialByFeatures;
  @attr('hash') availableTrials;
  @attr pricePlanAnnualPriceVatIncluded;
  @attr pricePlanMonthlyPriceVatIncluded;

  @belongsTo('organization', { async: true, inverse: null }) organization;
  @belongsTo('subscription-product', { async: false, inverse: null }) product;

  get activeTrialRemainingDays() {
    let { activeTrial } = this;
    if (!activeTrial) {
      return 0;
    }
    // Compare dates by day and hours, ex: when less than 23 hours differenceInDays will return 0
    let endDate = dayjs(activeTrial.end_date).endOf('day');
    let today = dayjs().startOf('hour');
    return endDate.diff(today, 'day');
  }

  get hasInitialTrial() {
    let { activeTrial } = this;

    if (!activeTrial) {
      return false;
    }
    return !activeTrial.previous_product_id && !activeTrial.previous_recurrence;
  }

  get hasInitialTrialUpsell() {
    return this.hasInitialTrial && this.activeTrialRemainingDays > INITIAL_TRIAL_UPSELL_CUTOFF_DAYS;
  }

  @waitFor
  async estimate() {
    let { organization_id, product_id, recurrence } = this.serialize();
    let data = { subscription: { organization_id, product_id, recurrence } };
    return await apiAction(this, { method: 'POST', path: 'estimate', data });
  }

  @waitFor
  async inactivateAddon() {
    return await apiAction(this, { method: 'PATCH', data: { active: false } });
  }

  findTrial(feature) {
    let id = this.trialByFeatures?.[underscore(feature)];
    if (!id) {
      return null;
    }

    return this.availableTrials?.find(({ productId }) => productId === id) || null;
  }

  get arAddonTrial() {
    return this.availableTrials.find(trial => trial.productCode === Addons.AccountsReceivable);
  }
}
