import Model, { attr, belongsTo } from '@ember-data/model';

import { equal } from 'macro-decorators';

import { INSURANCE_CONTRACT_STATUS } from 'qonto/constants/insurance-hub';

export default class InsuranceContractModel extends Model {
  @attr('string') name;
  @attr('string') contractId;
  @attr('string') providerSlug;
  @attr('string') type;
  @attr('string') status;
  @attr('string') troubleshootingUrl;
  @attr('string') serviceUrl;
  @attr('date') expirationDate;
  @attr('date') startDate;
  @attr('date') renewalDate;
  @attr('string') paymentFrequency;
  @attr price;
  @attr documents;

  @belongsTo('organization', { async: true, inverse: null }) organization;

  @equal('status', INSURANCE_CONTRACT_STATUS.EXPIRED) isExpired;
}
